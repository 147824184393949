import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-df8e5dc2"), n = n(), _popScopeId(), n); };
var _hoisted_1 = {
    key: 0,
    class: "position-absolute top-0 start-100 badge badge-circle badge-primary z_index_1",
    style: { "transform": "translate(-75%, -25%)" }
};
var _hoisted_2 = { class: "px-1 base_icon" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_base_spinner = _resolveComponent("base-spinner");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["text-center flex-column", [_ctx.badge && 'position-relative']])
    }, [
        (_ctx.badge)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.badge), 1))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
            (!_ctx.showLoading)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    style: _normalizeStyle("height: " + _ctx.fixedHW + "px !important; width: " + _ctx.fixedHW + "px !important; font-size: " + _ctx.iconSize + "px"),
                    class: _normalizeClass([[
                            _ctx.iconName,
                            _ctx.borderRound && 'rounded-circle',
                            !_ctx.zeroHW && 'w-40px h-40px btn-icon',
                            !_ctx.noBorder && 'btn-outline btn-outline-secondary',
                            _ctx.zeroHW && 'btn-icon-zero-width p-0',
                            _ctx.textSuccess && 'text-success',
                            _ctx.textDanger && 'text-danger',
                            _ctx.textPrimary && 'text-primary',
                            _ctx.isBlackColor && 'text-dark',
                            _ctx.isTransparent && 'bg-transparent',
                            _ctx.isPurpleColor && 'text_primary_purple',
                            _ctx.isGreenColor && 'text_primary_green',
                            _ctx.isWhiteColor && 'text-white',
                            _ctx.isHoverWhiteColor && 'hover_white',
                            _ctx.isGoldenColor && 'text_primary_golden',
                            _ctx.isGreenBackground && 'bg_primary_green_i',
                            _ctx.isBrownBackground && 'bg_primary_brown',
                            _ctx.isDangerBackground && 'bg_primary_danger',
                            !_ctx.iconSize && 'fs_20',
                        ], "btn cart_list m-0"]),
                    role: "button",
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            return (_ctx.gotoLink && _ctx.gotoLink.apply(_ctx, args));
                        })
                }, null, 6))
                : _createCommentVNode("", true),
            _createVNode(_component_base_spinner, { loading: _ctx.showLoading }, null, 8, ["loading"])
        ]),
        _createElementVNode("span", {
            class: _normalizeClass(["text_below_icon", [
                    _ctx.textSuccess && 'text-success',
                    _ctx.textPrimary && 'text-primary',
                    _ctx.textDanger && 'text-danger',
                    _ctx.isGreenColor && 'text_primary_green',
                ]]),
            style: _normalizeStyle([
                _ctx.badge &&
                    "display: flex;\n    padding-top: 0.1rem;\n    justify-content: center;",
            ])
        }, _toDisplayString(_ctx.text ? _ctx.$t(_ctx.text) : null), 7)
    ], 2));
}
