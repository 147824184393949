import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = { class: "py-5 overflow-hidden" };
var _hoisted_2 = { class: "nav nav-tabs nav-line-tabs mb-5 fs-5" };
var _hoisted_3 = ["href"];
var _hoisted_4 = {
    class: "tab-content main_div mx-auto",
    id: "myTabContent",
    style: { "max-width": "992px" }
};
var _hoisted_5 = {
    class: "tab-pane fade active show",
    id: "kt_tab_pane_order_summary",
    role: "tabpanel"
};
var _hoisted_6 = { class: "card-title" };
var _hoisted_7 = { class: "table-responsive" };
var _hoisted_8 = { class: "table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px" };
var _hoisted_9 = { class: "fw-bold text-gray-600" };
var _hoisted_10 = { class: "text-muted" };
var _hoisted_11 = { class: "d-flex align-items-center" };
var _hoisted_12 = { class: "fw-bolder text-end" };
var _hoisted_13 = { class: "d-flex flex-column gap-7 gap-lg-10" };
var _hoisted_14 = { class: "d-flex flex-column flex-xl-row gap-7 gap-lg-10" };
var _hoisted_15 = { class: "position-absolute top-0 end-0 opacity-10 pe-none text-end" };
var _hoisted_16 = ["src"];
var _hoisted_17 = { class: "card-title" };
var _hoisted_18 = {
    class: "tab-pane fade",
    id: "kt_tab_pane_order_history",
    role: "tabpanel"
};
var _hoisted_19 = { class: "card-title" };
var _hoisted_20 = { class: "table-responsive" };
var _hoisted_21 = { class: "table-responsive" };
var _hoisted_22 = { class: "table align-middle table-row-dashed fs-6 gy-5 mb-0" };
var _hoisted_23 = { class: "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0" };
var _hoisted_24 = { class: "fw-bold text-gray-600" };
var _hoisted_25 = { class: "d-flex align-items-center" };
var _hoisted_26 = { class: "symbol symbol-50px" };
var _hoisted_27 = { class: "ms-5" };
var _hoisted_28 = { class: "fw-bolder text-gray-600 text-hover-primary" };
var _hoisted_29 = { class: "fs-7 text-muted" };
var _hoisted_30 = { class: "text-end" };
var _hoisted_31 = { class: "text-end" };
var _hoisted_32 = { class: "text-end" };
var _hoisted_33 = { class: "text-end" };
var _hoisted_34 = {
    colspan: "4",
    class: "text-end"
};
var _hoisted_35 = { class: "text-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_base_progress_loading = _resolveComponent("base-progress-loading");
    var _component_base_icon = _resolveComponent("base-icon");
    var _component_base_card = _resolveComponent("base-card");
    var _component_base_flex = _resolveComponent("base-flex");
    var _component_base_badge = _resolveComponent("base-badge");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullData.title, function (tabTitle) {
                return (_openBlock(), _createElementBlock("li", {
                    class: "nav-item",
                    key: tabTitle === null || tabTitle === void 0 ? void 0 : tabTitle.name
                }, [
                    _createElementVNode("a", {
                        class: _normalizeClass(["nav-link", [(tabTitle === null || tabTitle === void 0 ? void 0 : tabTitle.active) && 'active']]),
                        "data-bs-toggle": "tab",
                        href: tabTitle === null || tabTitle === void 0 ? void 0 : tabTitle.href
                    }, [
                        _createElementVNode("span", null, _toDisplayString(tabTitle === null || tabTitle === void 0 ? void 0 : tabTitle.name), 1)
                    ], 10, _hoisted_3)
                ]));
            }), 128))
        ]),
        _createVNode(_component_base_progress_loading, {
            formLoading: _ctx.loading.form,
            class: "mt-xs-5 mt-md-5 mt-0"
        }, null, 8, ["formLoading"]),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_base_flex, { class: "flex-column flex-xl-row gap-7 gap-lg-10 mb-5" }, {
                    default: _withCtx(function () { return [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullData.cardWithTables, function (card) {
                            return (_openBlock(), _createBlock(_component_base_card, {
                                class: "card-flush py-4 flex-row-fluid",
                                key: card === null || card === void 0 ? void 0 : card.title
                            }, {
                                header: _withCtx(function () { return [
                                    _createElementVNode("div", _hoisted_6, [
                                        _createElementVNode("h2", null, _toDisplayString(card === null || card === void 0 ? void 0 : card.title), 1)
                                    ])
                                ]; }),
                                body: _withCtx(function () { return [
                                    _createElementVNode("div", _hoisted_7, [
                                        _createElementVNode("table", _hoisted_8, [
                                            _createElementVNode("tbody", _hoisted_9, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(card === null || card === void 0 ? void 0 : card.table, function (tr) {
                                                    return (_openBlock(), _createElementBlock("tr", {
                                                        key: tr === null || tr === void 0 ? void 0 : tr.text
                                                    }, [
                                                        _createElementVNode("td", _hoisted_10, [
                                                            _createElementVNode("div", _hoisted_11, [
                                                                _createVNode(_component_base_icon, {
                                                                    iconName: tr === null || tr === void 0 ? void 0 : tr.icon,
                                                                    noBorder: ""
                                                                }, null, 8, ["iconName"]),
                                                                _createTextVNode(" " + _toDisplayString(tr === null || tr === void 0 ? void 0 : tr.text), 1)
                                                            ])
                                                        ]),
                                                        _createElementVNode("td", _hoisted_12, _toDisplayString(tr === null || tr === void 0 ? void 0 : tr.result), 1)
                                                    ]));
                                                }), 128))
                                            ])
                                        ])
                                    ])
                                ]; }),
                                _: 2
                            }, 1024));
                        }), 128))
                    ]; }),
                    _: 1
                }),
                _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullData.cardWithBigIcon, function (card) {
                            return (_openBlock(), _createBlock(_component_base_card, {
                                key: card.title,
                                class: "card-flush py-4 flex-row-fluid overflow-hidden",
                                style: { "min-height": "165px" }
                            }, {
                                header: _withCtx(function () { return [
                                    _createElementVNode("div", _hoisted_17, [
                                        _createElementVNode("h2", null, _toDisplayString(card === null || card === void 0 ? void 0 : card.title), 1)
                                    ])
                                ]; }),
                                body: _withCtx(function () { return [
                                    _createTextVNode(_toDisplayString(card === null || card === void 0 ? void 0 : card.subTitle), 1)
                                ]; }),
                                default: _withCtx(function () { return [
                                    _createElementVNode("div", _hoisted_15, [
                                        _createElementVNode("img", {
                                            src: require('@/assets/svg/truck.svg') || '',
                                            class: "w-175px"
                                        }, null, 8, _hoisted_16)
                                    ])
                                ]; }),
                                _: 2
                            }, 1024));
                        }), 128))
                    ])
                ])
            ]),
            _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_base_card, { class: "card-flush py-4 flex-row-fluid overflow-hidden" }, {
                    header: _withCtx(function () {
                        var _a;
                        return [
                            _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("h2", null, "Order #" + _toDisplayString((_a = _ctx.order) === null || _a === void 0 ? void 0 : _a.id), 1)
                            ])
                        ];
                    }),
                    body: _withCtx(function () {
                        var _a, _b;
                        return [
                            _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", _hoisted_21, [
                                    _createElementVNode("table", _hoisted_22, [
                                        _createElementVNode("thead", null, [
                                            _createElementVNode("tr", _hoisted_23, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullData.ListingHeader, function (header) {
                                                    return (_openBlock(), _createElementBlock("th", {
                                                        key: header === null || header === void 0 ? void 0 : header.name,
                                                        class: _normalizeClass(header === null || header === void 0 ? void 0 : header.class)
                                                    }, _toDisplayString(header === null || header === void 0 ? void 0 : header.name), 3));
                                                }), 128))
                                            ])
                                        ]),
                                        _createElementVNode("tbody", _hoisted_24, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_a = _ctx.order) === null || _a === void 0 ? void 0 : _a.order_products, function (product) {
                                                var _a, _b, _c, _d, _e;
                                                return (_openBlock(), _createElementBlock("tr", {
                                                    key: product === null || product === void 0 ? void 0 : product.id
                                                }, [
                                                    _createElementVNode("td", null, [
                                                        _createElementVNode("div", _hoisted_25, [
                                                            _createElementVNode("span", _hoisted_26, [
                                                                _createElementVNode("span", {
                                                                    class: "symbol-label",
                                                                    style: _normalizeStyle("background-image: url(" + (((_a = product === null || product === void 0 ? void 0 : product.product) === null || _a === void 0 ? void 0 : _a.images[0]) ? (_c = (_b = product === null || product === void 0 ? void 0 : product.product) === null || _b === void 0 ? void 0 : _b.images[0]) === null || _c === void 0 ? void 0 : _c.image_path : '') + ")")
                                                                }, null, 4)
                                                            ]),
                                                            _createElementVNode("div", _hoisted_27, [
                                                                _createElementVNode("span", _hoisted_28, _toDisplayString((_d = product === null || product === void 0 ? void 0 : product.product) === null || _d === void 0 ? void 0 : _d.name), 1),
                                                                _createElementVNode("div", _hoisted_29, [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product === null || product === void 0 ? void 0 : product.order_option, function (option, key) {
                                                                        return (_openBlock(), _createBlock(_component_base_badge, {
                                                                            key: key,
                                                                            class: "my-1 p-2 mx-1"
                                                                        }, {
                                                                            default: _withCtx(function () { return [
                                                                                _createTextVNode(_toDisplayString(option.name) + ":" + _toDisplayString(option.value), 1)
                                                                            ]; }),
                                                                            _: 2
                                                                        }, 1024));
                                                                    }), 128))
                                                                ])
                                                            ])
                                                        ])
                                                    ]),
                                                    _createElementVNode("td", _hoisted_30, _toDisplayString((_e = product === null || product === void 0 ? void 0 : product.product) === null || _e === void 0 ? void 0 : _e.code), 1),
                                                    _createElementVNode("td", _hoisted_31, _toDisplayString(product === null || product === void 0 ? void 0 : product.quantity), 1),
                                                    _createElementVNode("td", _hoisted_32, " $" + _toDisplayString(Number(product === null || product === void 0 ? void 0 : product.price).toFixed(2)), 1),
                                                    _createElementVNode("td", _hoisted_33, " $" + _toDisplayString(Number(product === null || product === void 0 ? void 0 : product.total).toFixed(2)), 1)
                                                ]));
                                            }), 128)),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_b = _ctx.order) === null || _b === void 0 ? void 0 : _b.order_total, function (meta) {
                                                return (_openBlock(), _createElementBlock("tr", {
                                                    key: meta.name
                                                }, [
                                                    _createElementVNode("td", _hoisted_34, _toDisplayString(meta === null || meta === void 0 ? void 0 : meta.title), 1),
                                                    _createElementVNode("td", _hoisted_35, _toDisplayString(meta === null || meta === void 0 ? void 0 : meta.text), 1)
                                                ]));
                                            }), 128))
                                        ])
                                    ])
                                ])
                            ])
                        ];
                    }),
                    _: 1
                })
            ])
        ])
    ]));
}
