import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "BaseIcon",
    props: {
        iconName: {
            type: String,
            required: true,
        },
        noBorder: {
            type: Boolean,
            required: false,
        },
        borderRound: {
            type: Boolean,
            required: false,
        },
        zeroHW: {
            type: Boolean,
            required: false,
        },
        showLoading: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: null,
        },
        textPrimary: {
            type: Boolean,
            default: false,
        },
        textSuccess: {
            type: Boolean,
            default: false,
        },
        textDanger: {
            type: Boolean,
            default: false,
        },
        badge: {
            type: Number,
            default: null,
        },
        isTransparent: {
            type: Boolean,
            default: false,
        },
        isPurpleColor: {
            type: Boolean,
            default: false,
        },
        isGreenColor: {
            type: Boolean,
            default: false,
        },
        isGreenBackground: {
            type: Boolean,
            default: false,
        },
        isBlackColor: {
            type: Boolean,
            default: false,
        },
        isWhiteColor: {
            type: Boolean,
            default: false,
        },
        isHoverWhiteColor: {
            type: Boolean,
            default: false,
        },
        isBrownBackground: {
            type: Boolean,
            default: false,
        },
        fixedHW: {
            type: String,
            default: null,
        },
        isGoldenColor: {
            type: Boolean,
            default: false,
        },
        link: {
            type: String,
            default: null,
        },
        iconSize: {
            type: String,
            default: null,
        },
        isDangerBackground: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props) {
        var router = useRouter();
        var gotoLink = function () {
            if (props.link) {
                router.push({ name: props.link });
            }
        };
        return {
            gotoLink: gotoLink,
        };
    },
});
