import { defineComponent } from "vue";
export default defineComponent({
    name: "BaseProgressLoading",
    props: {
        formLoading: {
            type: Boolean,
            default: false,
        },
    },
});
