import { __awaiter, __generator } from "tslib";
import { showOrder } from "@/api/OrderApi";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
    name: "OrderShow",
    setup: function () {
        var _this = this;
        var route = useRoute();
        var order = ref({});
        var loading = ref({
            form: false,
        });
        var fullData = computed(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return {
                title: [
                    {
                        name: "Summary",
                        active: true,
                        href: "#kt_tab_pane_order_summary",
                    },
                    {
                        name: "Detail",
                        active: false,
                        href: "#kt_tab_pane_order_history",
                    },
                ],
                cardWithTables: [
                    {
                        title: "Order Detail #" + ((_a = order === null || order === void 0 ? void 0 : order.value) === null || _a === void 0 ? void 0 : _a.invoice_no),
                        table: [
                            {
                                icon: "bi bi-calendar-date",
                                text: "Date Added",
                                result: (_b = order === null || order === void 0 ? void 0 : order.value) === null || _b === void 0 ? void 0 : _b.date_added,
                            },
                            {
                                icon: "bi bi-credit-card",
                                text: "Payment Method",
                                result: (_c = order === null || order === void 0 ? void 0 : order.value) === null || _c === void 0 ? void 0 : _c.payment_method_name,
                            },
                            {
                                icon: "bi bi-truck",
                                text: "Shipping Method",
                                result: (_d = order === null || order === void 0 ? void 0 : order.value) === null || _d === void 0 ? void 0 : _d.shipping_method_name,
                            },
                        ],
                    },
                    {
                        title: "Customer Details",
                        table: [
                            {
                                icon: "bi bi-person-circle",
                                text: "Customer",
                                result: ((_e = order === null || order === void 0 ? void 0 : order.value) === null || _e === void 0 ? void 0 : _e.firstname) + " " + ((_f = order === null || order === void 0 ? void 0 : order.value) === null || _f === void 0 ? void 0 : _f.lastname),
                            },
                            {
                                icon: "bi bi-envelope",
                                text: "Email",
                                result: (_g = order === null || order === void 0 ? void 0 : order.value) === null || _g === void 0 ? void 0 : _g.email,
                            },
                            {
                                icon: "bi bi-telephone",
                                text: "Phone",
                                result: (_h = order === null || order === void 0 ? void 0 : order.value) === null || _h === void 0 ? void 0 : _h.telephone,
                            },
                        ],
                    },
                ],
                cardWithBigIcon: [
                    {
                        title: "Shipping Address",
                        subTitle: (_j = order === null || order === void 0 ? void 0 : order.value) === null || _j === void 0 ? void 0 : _j.shipping_address_1,
                        icon: "bi bi-truck",
                    },
                ],
                ListingHeader: [
                    {
                        name: "Product",
                        class: "min-w-175px",
                    },
                    {
                        name: "SKU",
                        class: "min-w-100px text-end",
                    },
                    {
                        name: "Qty",
                        class: "min-w-100px text-end",
                    },
                    {
                        name: "Unit Price",
                        class: "min-w-100px text-end",
                    },
                    {
                        name: "Total",
                        class: "min-w-100px text-end",
                    },
                ],
            };
        });
        var getOrder = function () { return __awaiter(_this, void 0, void 0, function () {
            var orderId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        orderId = route.params.orderId;
                        if (!orderId) return [3 /*break*/, 2];
                        loading.value.form = true;
                        return [4 /*yield*/, showOrder(route.params.orderId)
                                .then(function (res) {
                                order.value = res.data.data;
                            })
                                .then(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loading.value.form = false;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        onMounted(function () {
            setCurrentPageBreadcrumbs("Order Details", ["Order"]);
            getOrder();
        });
        return { fullData: fullData, order: order, loading: loading };
    },
});
