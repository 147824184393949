import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-1ea355e2"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "card" };
var _hoisted_2 = { class: "card-header border-0 cursor-pointer mb-5" };
var _hoisted_3 = { class: "card-title w-100" };
var _hoisted_4 = { class: "card-body" };
var _hoisted_5 = { class: "px-0" };
var _hoisted_6 = { class: "card-footer d-flex justify-content-end py-6 px-9" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true)
            ])
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "body", {}, undefined, true)
            ])
        ]),
        _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ])
    ]));
}
