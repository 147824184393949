import { defineComponent } from "vue";
export default defineComponent({
    name: "BaseSpinner",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
});
